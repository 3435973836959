// List of Countries with activated price comparison 
export const supportedCountries = [
    'US', 'GB', 'HU', 'ES', 'FR', 'IT', 'DE', 'NL', 'CZ', 'DK', 'AT', 'SK', 'SE',
    'RO', 'CA', 'BE', 'PL', 'CH', 'PT', 'SI', 'GR', 'IE', 'AU', 'HR', 'BG',
  ];


// Define Countries with Currency before the amount (e.g., $49.80)
export const countriesWithSymbolBeforeAmount = [
    'CH', // Switzerland (CHF 49.80)
    'US', // United States ($49.80)
    'GB', // United Kingdom (£49.80)
    'CA', // Canada (C$49.80)
    'AU', // Australia (A$49.80)
    'HK', // Hong Kong (HK$49.80)
    'SG', // Singapore (S$49.80)
    'JP', // Japan (¥4980)
    'CN', // China (¥4980)
    'KR', // South Korea (₩49,800)
    'NZ', // New Zealand (NZ$49.80)
    'BR', // Brazil (R$49.80)
    'IN', // India (₹49.80)
    'TH', // Thailand (฿49.80)
    'MX', // Mexico (Mex$49.80)
    'PH', // Philippines (₱49.80)
    'MY', // Malaysia (RM49.80)
    'ID', // Indonesia (Rp49,800)
    'ZAR', // South Africa (R49.80)
    'ILS', // Israel (₪49.80)
  ];
  
  
 
// Convert these Currencies into symbols
export const currencySymbols = {
    USD: '$', // US Dollar
    EUR: '€', // Euro
    GBP: '£', // British Pound
    HUF: 'Ft', // Hungarian Forint
    SEK: 'kr', // Swedish Krona
    DKK: 'kr', // Danish Krone
    CHF: 'CHF', // Swiss Franc
    PLN: 'zł', // Polish Zloty
    CZK: 'Kč', // Czech Koruna
    RON: 'lei', // Romanian Leu
    AUD: 'A$', // Australian Dollar
    CAD: 'C$', // Canadian Dollar
    HRK: 'kn', // Croatian Kuna
    BGN: 'лв', // Bulgarian Lev
    NOK: 'kr', // Norwegian Krone
    JPY: '¥', // Japanese Yen
    SGD: 'S$', // Singapore Dollar
    HKD: 'HK$', // Hong Kong Dollar
    INR: '₹', // Indian Rupee
    CNY: '¥', // Chinese Yuan
    KRW: '₩', // South Korean Won
    BRL: 'R$', // Brazilian Real
    MXN: 'Mex$', // Mexican Peso
    ZAR: 'R', // South African Rand
    NZD: 'NZ$', // New Zealand Dollar
    MYR: 'RM', // Malaysian Ringgit
    THB: '฿', // Thai Baht
    UAH: '₴', // Ukraine
    ILS: '₪', // Israel
    RUB: '₽', // Russia
    // Add more if necessary
  };
  

