<template>
    <div v-if="!consentGiven && !isIOSApp()" class="cookie-consent">
       <p>
      This website uses cookies to ensure you get the best experience on our website. Learn more in our
      
      <a href="https://perfumeprophet.com/app-privacy" target="_blank" class="privacy-link">
       Privacy Policy.
      </a>
    </p>
      <v-btn @click="declineCookies" class="custom-button">Decline</v-btn>
      <v-btn @click="acceptCookies" class="custom-button">Accept</v-btn>
      <br>
    </div>
  </template>
  
<script>
import { Capacitor } from '@capacitor/core';
  
export default {
  data() {
    return {
      consentGiven: false,
    };
  },
  methods: {
    acceptCookies() {
      localStorage.setItem('cookie-consent', 'accepted');
      this.consentGiven = true;
      this.initializeAnalytics();
    },
    declineCookies() {
      localStorage.setItem('cookie-consent', 'declined');
      this.consentGiven = true;
    },
    initializeAnalytics() {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=G-1X9GXQJDWZ`;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        window.gtag = gtag;
        gtag('js', new Date());
        gtag('config', 'G-1X9GXQJDWZ', { 'anonymize_ip': true });
      };
    },
    checkConsent() {
      const consent = localStorage.getItem('cookie-consent');
      if (consent === 'accepted') {
        this.consentGiven = true;
        this.initializeAnalytics();
      } else if (consent === 'declined') {
        this.consentGiven = true;
      }
    },
      isIOSApp() {
      return Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios';
    },
  },
  mounted() {
    this.checkConsent();
  },
};
</script>
  
  <style>
  .cookie-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 1400px;
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 1em;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    z-index: 9999; /* Ensures the banner is always on top */
    margin: 0 auto;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
  }
  
  .custom-button {
    background-color: white !important;
    color: black !important;
    border: 2px solid black !important;
    border-radius: 10px !important;
    margin: 0 10px;
  }

  .privacy-link {
  color: white !important;
  text-decoration: underline;
  
}

  </style>