<template>
  <div>
    <!-- Heart Icon for Adding to Favorites -->
    <v-btn
      icon
      @click="handleFavoriteToggle"
       :style="`position: absolute; top: ${top}px; left: ${left}px;`"
    >
    <div class="icon-wrapper">
  <v-icon :color="isFavorite ? 'red' : '#dcdcdc'">
    {{ isFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}
  </v-icon>
</div>


    </v-btn>

    <!-- Feedback Message -->
    <div
      v-if="feedbackMessage"
      :class="[feedbackType === 'added' ? 'favorite-added' : 'favorite-removed']"
      class="feedback-box"
      :style="`position: absolute; top: ${top + 8}px; left: ${left + 50}px;`" 
    >
      {{ feedbackMessage }}
    </div>
  </div>
</template>

<script>

import { EventBus } from '@/utils/EventBus'; // Adjust the path as necessary
export default {
  props: ['perfumeId', 'top', 'left'],
  data() {
    return {
      feedbackMessage: '',
      feedbackType: '',
      favorites: JSON.parse(localStorage.getItem('favorites')) || [],
    };
  },
  computed: {
    isFavorite() {
      return this.favorites.includes(this.perfumeId);
    }
  },
  methods: {
    handleFavoriteToggle() {
      if (this.isFavorite) {
        this.toggleFavorite();
        this.showFeedback('Removed from your Favorites', 'removed');
      } else {
        this.toggleFavorite();
        this.showFeedback('Added to your Favorites!', 'added');
      }
    },
    
    showFeedback(message, type) {
      this.feedbackMessage = message;
      this.feedbackType = type;

      setTimeout(() => {
        this.feedbackMessage = '';
        this.feedbackType = '';
      }, 1000);
    },

    toggleFavorite() {
      const index = this.favorites.indexOf(this.perfumeId);
      if (index > -1) {
        this.favorites.splice(index, 1); // Remove
      } else {
        this.favorites.push(this.perfumeId); // Add
      }
      localStorage.setItem('favorites', JSON.stringify(this.favorites));
      EventBus.$emit('updateFavorites', this.favorites);
    },

    syncFavorites(newFavorites) {
      this.favorites = newFavorites;
    },  


  },

  created() {
    EventBus.$on('updateFavorites', this.syncFavorites);
  },
  beforeDestroy() {
    EventBus.$off('updateFavorites', this.syncFavorites);
  },

  
};
</script>

<style scoped>
.feedback-box {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  margin: 5px 0;
  background-color: #222222;
  border-radius: 10px;
  color: white;
  font-size: 14px;
  opacity: 0;
  z-index: 1000;
  animation: fade-in-out 1.2s ease-in-out forwards;
}

favorite-added {
  color: white; /* Matches heart's red color */
}

.favorite-removed {
  color: white; /* White text for removed */
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
    transform: translateY(10px); /* Subtle rise animation */
  }
  30% {
    opacity: 1;
    transform: translateY(0); /* Back to normal */
  }
  70% {
    opacity: 1;
    transform: translateY(0); /* Pause briefly */
  }
  100% {
    opacity: 0;
    transform: translateY(-10px); /* Subtle fade out and lift */
  }
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  border-radius: 50%; /* Makes the div circular */
  background-color: rgba(255, 255, 255, 1); /* White with 0.5 opacity */
}


</style>
