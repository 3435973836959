<template>
    <div>
      {{ perfume.name }} is 
      {{ perfume.article ? perfume.article : 'a' }} 
      {{ perfume.concentration ? perfume.concentration : 'perfume' }} 
      by {{ perfume.brand}}{{ perfume.year && !isNaN(parseInt(perfume.year, 10)) && parseInt(perfume.year, 10) > 0 ? ' that was released in ' + parseInt(perfume.year, 10) : '' }}.

      This fragrance is characterized by its {{ formatCombinedTypes(perfume.combined_types) }} tones.
      It combines the vibrant essence of {{ formattedNotes(perfume.notes) }}{{ hintsOfNotes(perfume.notes) }}. {{ sillageDescription() }} The scent has {{ perfume.longevity }} longevity.
    </div>
  </template>
  
  <script>
  export default {
    props: ['perfume'],
    methods: {
      formatCombinedTypes(combinedTypes) {
        const typesArray = combinedTypes.split('-');
        const lowerCaseArray = typesArray.map(type => type.toLowerCase());
        const formattedString = lowerCaseArray.join(', ').replace(/, ([^,]*)$/, ' and $1');
        return formattedString;
      },
      formattedNotes() {
        const notesArray = this.perfume.notes.toLowerCase().split(', ').map(note => note.trim());
        const selectedNotes = notesArray.slice(0, 3);
        if (selectedNotes.length > 1) {
          const lastNote = selectedNotes.pop();
          return `${selectedNotes.join(', ')} and ${lastNote}`;
        }
        return selectedNotes[0] || '';
      },
      hintsOfNotes() {
        const notesArray = this.perfume.notes.split(',').map(note => note.trim().toLowerCase());
        const hintsNotes = notesArray.slice(3, 5);
        if (hintsNotes.length === 2) {
          return ` with hints of ${hintsNotes[0]} and ${hintsNotes[1]}`;
        } else if (hintsNotes.length === 1) {
          return ` with hints of ${hintsNotes[0]}`;
        }
        return '';
      },
      sillageDescription() {
        if (this.perfume.sillage === 'medium' || this.perfume.sillage === 'low') {
          return 'The scent is perceived as smooth and subtle.';
        } else if (this.perfume.sillage === 'strong' || this.perfume.sillage === 'very strong') {
          return 'The scent has a strong projection.';
        }
        return '';
      }
    }
  };
  </script>
  