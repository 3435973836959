<template>
  <div style="width:100%; margin:20px auto; height:400px">
    <!-- Configuring slider components -->
    <slider ref="slider" :options="options" @slide='onSlide' @tap='onTap' @init='onInit'>
      <slideritem v-for="(item, index) in pages" :key="index" :style="item.style">
        {{ item.html }}
      </slideritem>
      <!-- Custom loading -->
      <div slot="loading">Loading...</div>
    </slider>
  </div>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider' // import slider components

export default {
  components: {
    slider,
    slideritem
  },
  data() {
    return {
      // Image or content list
      pages: [
        { html: 'Slide 1', style: { background: '#1bbc9b' } },
        { html: 'Slide 2', style: { background: '#4bbfc3' } },
        { html: 'Slide 3', style: { background: '#7baabe' } }
      ],
      // Slider configuration
      options: {
        currentPage: 0,              // Start from first slide
        thresholdDistance: 50,      // Reduced swipe threshold distance
        thresholdTime: 200,          // Lower threshold time for faster response
        loop: true,                  // Enable looping
        direction: 'horizontal',     // Ensure slider is horizontal
        effect: 'slide',             // Use 'slide' effect for smooth sliding
        speed: 600,                  // Transition speed in milliseconds
        timingFunction: 'ease',      // CSS easing function to smooth transitions
        centeredSlides: true,        // Ensure slides are centered
        preventRebound: true,        // Stop rebound to make it smoother
        preventDocumentMove: true,  // Allow page scrolling
        slidesToScroll: 1            // Scroll one slide at a time
      }
    }
  },
  methods: {
    onSlide(data) {
      console.log('Slide event data:', data)
    },
    onTap(data) {
      console.log('Tap event data:', data)
    },
    onInit(data) {
      console.log('Initialization data:', data)
    }
  }
}
</script>

<style scoped>
/* Additional styles for slideritem */
.slideritem {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
}
</style>
