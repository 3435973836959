<template>
    <div>    

      <div v-for="(note, index) in notesArray" :key="index">
      {{ note.trim() }} <!-- Trimming to remove any accidental whitespace -->
    </div>

    </div>
  </template>
  
  <script>
  export default {
    props: ['perfume'],
    
    methods: {     
    },

    computed: {
    // Computes an array of notes from the perfume.notes string
    notesArray() {
      if (this.perfume && this.perfume.notes) {
        return this.perfume.notes.split(','); // Split the notes string into an array by comma
      }
      return []; // Return an empty array if no notes are available
    }
  },

  };
  </script>
  