<template>
   <div id="example" v-if="filteredSlides.length >= 3">
    <carousel-3d ref="carousel" :count="filteredSlides.length" :border=0 :width=330 :height=320 :space=330 :perspective=0
                  :animationSpeed=500 :loop="true" :controls-visible=!mobile @after-slide-change="updateActiveIndex">
      <slide v-for="(slide, index) in filteredSlides" :index="index" :key="index">
        
        <figure>
        <img :src="slide.src" alt="Slide image">
        </figure>
       <!-- <figcaption v-if="isActive(index)">          
           {{ extractFilename(slide.src) }} 
        </figcaption> -->
      </slide>
      
    </carousel-3d>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
components: {
  Carousel3d,
  Slide
},
data() {
    return {
      startY: 0,
      startX: 0,
      activeIndex: 0, // Initial active index
    };
  },

props: ['notes', 'mobile'],
computed: {


  filteredSlides() {
    const usedImages = new Set();  // Set to track images already used

    return this.notes.map(note => {
      // Normalize the note by removing diacritics and 'Absolute', then trim
      let normalizedNote = note
        .normalize('NFD') // Normalize to decompose special characters
        .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
        .replace(/Absolute/gi, '') // Remove 'Absolute' if present
        .trim();
      let parts = normalizedNote.split(' ');

      // Try to find an image starting with the full term and progressively reducing
      for (let i = 0; i < parts.length; i++) {
        let imageName = parts.slice(i).join('_').toLowerCase() + '.webp';
        if (!usedImages.has(imageName)) { // Check if the image has not been used yet
          try {
            const imageSrc = require(`@/assets/notes/${imageName}`);
            usedImages.add(imageName); // Mark this image as used
            return { src: imageSrc };
          } catch (e) {
            // Continue to the next iteration if the image file is not found
            continue;
          }
        }
      }
      return null; // Return null if no images are found for any reduced terms
    }).filter(slide => slide !== null); // Filter out null values
  }



},

mounted() {
    console.log("Mobile value on mounted:", this.mobile);  // Log the mobile value when component mounts
    this.$nextTick(() => {
    this.preventScrollOnSwipe();
  });
  },

  
  
  methods: {

    extractFilename(path) {
    let filename = path.split('/').pop(); // Get the filename part of the path
    filename = filename.substring(0, filename.indexOf('.')); // Remove extension
    filename = filename.replace(/_/g, ' '); // Replace underscores with spaces
    return filename.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '); // Capitalize each word
  },

  isActive(index) {
      return index === this.activeIndex; // Check if the index is the active index
    },

    updateActiveIndex(index) {
      this.activeIndex = index; // Update active index when the slide changes
    },

    preventScrollOnSwipe() {
    const carouselElement = this.$refs.carousel.$el; // Make sure to access the DOM element
    if (carouselElement) {
      carouselElement.addEventListener('touchstart', this.handleTouchStart);
      carouselElement.addEventListener('touchmove', this.handleTouchMove, { passive: false });
    }
  },

  handleTouchStart(e) {
    this.startX = e.touches[0].clientX;
    this.startY = e.touches[0].clientY;
    //console.log('Touch start X:', this.startX, 'Y:', this.startY);
  },

  handleTouchMove(e) {
    const moveX = e.touches[0].clientX;
    const moveY = e.touches[0].clientY;
    //console.log('Touch move X:', moveX, 'Y:', moveY);

    const diffX = moveX - this.startX;
    const diffY = moveY - this.startY;

    //console.log(`Horizontal movement: ${diffX}, Vertical movement: ${diffY}`);
    if (Math.abs(diffX) > Math.abs(diffY)) {
      //console.log('Preventing default behavior due to horizontal swipe');
      e.preventDefault();
    } else {
      //console.log('Allowing vertical movement');
    }
  },
  }

};

</script>

<style>
#example .carousel-3d-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px; /* Adjusted to fit design needs */
  border-radius: 6px;
}

#example img {
  max-width: 100%;
  height: auto;

}

.carousel-3d-container figure {
  margin:0;
}

.carousel-3d-container figcaption {
  position: absolute;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  bottom: -2px;
  position: absolute;
  font-size: 16px;
  min-width: 100%;
  box-sizing: border-box;
}

</style>
