<template>
  <v-app :style="{ backgroundColor: $vuetify.theme.currentTheme.background }">
    <v-main>
      <v-container fluid class="d-flex align-center justify-center startpage-container">
        <v-row>
          <!-- Instagram Icon -->
          <v-col cols="12" class="text-right">
            <a href="https://www.instagram.com/perfume.prophet/" target="_blank" class="instagram-link">
              <v-icon size="30px" color="black">mdi-instagram</v-icon>
            </a>
          </v-col>

          <v-col cols="12" class="text-left">
            <div v-if="isIOSApp()" style="height: 40px;"></div> <!-- Adjust the height as necessary -->
            <h1 class="app-title">
              <span class="underline">Perfume</span> Prophet.
            </h1>
          </v-col>

          <v-col cols="12" md="6" class="d-flex justify-center pr-md-10">
            <div class="image-container-start" @click="handleTap">
              <img
                :src="carouselImages[carouselIndex]"
                alt="Fragrance Image"
                class="rounded-image"
              />
              <div class="dots">
                <span
                  v-for="(image, index) in carouselImages"
                  :key="index"
                  class="dot"
                  :class="{ active: index === carouselIndex }"
                  @click="setCarouselIndex(index)"
                ></span>
              </div>
            </div>
          </v-col>



          <v-col cols="12" md="6" class="text-left pl-md-10">
            <h1 class="main-title">Discover your perfect perfume</h1>
            <p class="description"><span class="highlight"></span> <span class="highlight"> Find your dream fragrance </span>within more than 2000 popular perfumes.</p>              
            <p class="description"><span class="highlight">Search and filter</span> by scent profile, notes, intensity, and more.</p>             
            <p class="description"><span class="highlight">Save your favorite perfumes </span> to your Favorites list <span class="mdi mdi-heart" style="color:rgb(188, 188, 188);"></span> </p>
            <p class="description"><span class="highlight"> Get inspiration</span> and discover new perfumes that match to your personal taste.</p>
<br>
<p class="description">
  <span 
    class="highlight clickable" 
    @click="showDemo = true"
  >
    <v-icon style="color:black">mdi-video</v-icon>&nbsp;Play demo 
  </span>
</p>



                  <!-- Added modal for video -->
              <v-dialog
          v-model="showDemo"
          max-width="800"
          class="demo-dialog"
          persistent
          @click:outside="showDemo = false"
        >
          <v-card>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="showDemo = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="video-container">
                <video
                  src="@/assets/full_vid.mp4"
                  autoplay
                  muted
                  loop
                  playsinline
                  poster
                  disablepictureinpicture
                  controlslist="nodownload nofullscreen noremoteplayback"
                  class="demo-video"
                ></video>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

  
            <v-btn dark fab color="black" class="explore-button" @click="navigateToApp">
              <span>Start Now</span>
              <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
            <br>
          </v-col>
        </v-row>
        <br>
        
      </v-container>
      <br>
    </v-main>
  </v-app>
</template>




<script>
import { Capacitor } from '@capacitor/core';

export default {
  name: 'StartPage',
  data() {
    return {
      carouselIndex: 0,
      carouselImages: [
        require('@/assets/startpage_image1.webp'),
        require('@/assets/startpage_image2.webp'),
        require('@/assets/startpage_image3.webp'),
        require('@/assets/startpage_image4.webp'),
      ],
      showDemo: false, // Controls the visibility of the video modal
      carouselInterval: null, // Store interval for automatic cycling
    };
  },
  methods: {
    isIOSApp() {
      return Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios';
    },
    navigateToApp() {
      this.$router.push({ name: 'PerfumeDataTable' });
    },
    startCarousel() {
      this.carouselInterval = setInterval(() => {
        this.carouselIndex =
          (this.carouselIndex + 1) % this.carouselImages.length;
      }, 4000); // Change image every 4 seconds
    },
    stopCarousel() {
      clearInterval(this.carouselInterval);
    },
    setCarouselIndex(index) {
      this.carouselIndex = index;
      this.stopCarousel();
    },
    handleTap() {
      this.carouselIndex =
        (this.carouselIndex + 1) % this.carouselImages.length;
      this.stopCarousel();
    },
  },
  mounted() {
    if (this.isIOSApp()) {
      console.log('Running on iOS Native App');
    }
    this.startCarousel();
  },
  beforeDestroy() {
    this.stopCarousel();
  },
};
</script>


<style scoped>
.startpage-container {
  min-height: 100vh;
  padding: 20px;
}

.rounded-image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

.image-container-start {
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  position: relative;
  cursor: pointer; /* Add pointer cursor for tap indication */

  display: flex;
  justify-content: center; 
}

.dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.dot {
  width: 9px;
  height: 9px; /* Corrected height value */
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: white;
}


.app-title {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 0px;
  color: #000;
}

.main-title {
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 55px;
  line-height: 1.22;
}

.description {
  font-size: 1.4rem;
  margin-bottom: 20px;
  color: rgb(188, 188, 188);
  font-weight: bold;
  line-height: 1.4;
}

.description .highlight {
  color: #000;
}

.explore-button {
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 16px;
  background-color: #000 !important;
  color: #fff;
  border-radius: 8px;
  padding: 12px 24px;
  width: 190px;
  height: 45px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.underline {
  text-decoration: underline;
}

.instagram-link {
  position: relative;
  top: 50px;
  right: 10px;
  z-index: 10;
  text-decoration: none;
}

.demo-button {
  margin-top: 10px;
  background-color: #ffffff !important;
  color: #000000;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: none !important;
}


.demo-dialog {
  /* Ensures dialog is centered and styled properly */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  overflow: hidden !important; /* Prevent scrolling */
  background-color: #ffffff !important;
}

.video-container {
  width: 100%;
  max-width: 800px; /* Adjust as needed */
  overflow: hidden;
  background-color: #ffffff !important;
  overflow: hidden !important; /* Prevent scrolling */
}

.demo-video {
  width: 100%; /* Makes the video responsive */
  height: auto; /* Maintains aspect ratio */
  border-radius: 8px; /* Optional: For rounded corners */
  object-fit: cover; /* Ensures proper scaling */
  box-shadow: none; /* Remove any shadows */
  background-color: #ffffff !important;
  overflow: hidden !important; /* Prevent scrolling */
}

.clickable {
  cursor: pointer; /* Displays the hand cursor to indicate clickability */
  /* Optional: Adds underline for clarity */
  color: black; /* Matches the icon color */
}

.clickable:hover {
  color: #555; /* Slight color change on hover for better feedback */
  text-decoration: none; /* Optional: Removes underline on hover */
}



</style>
