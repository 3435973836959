<template>
    <div class="dialog-container">
      <div class="instructions-header">
        <br>
        Click on the colorful buttons to get started.        
      </div>

      <br>
  
      <div class="instructions-container">
    <div class="mobile-scent-types-instr">
      <!-- Display only the first five or all based on 'showAllTypes' -->
      <v-chip 
        v-for="(type, index) in displayedTypes"
        :key="index"
        :color="getScentTypeColor(type)"
        class="white--text text-uppercase mobile-chip-custom"
        @click="goToPerfumesWithScentType(type)"
      >
        {{ type }}
      </v-chip>
    </div>

 <!-- Shuffle Button -->
 <v-btn icon @click="shuffleTypes">
      <v-icon>mdi-shuffle-variant</v-icon>
    </v-btn>
  </div>

      <br>
      
      <div class="button-container">
      <v-btn
        color="black"
        style="border-radius: 8px;box-shadow: none !important;"
        class="white--text"
        @click="$emit('close-dialog')"
      >
        OK
      </v-btn>
    </div>

    </div>
</template>
  
<script>
  import { getScentTypeColor } from '@/utils/colorMapping.js';
  
  export default {
    data() {
      return {
        scentTypes: ['Fresh', 'Flowery', 'Sweet', 'Fruity', 'Citric', 'Woody', 'Creamy', 'Aromatic', 'Powdery', 'Green', 'Aquatic', 
        'Synthetic', 'Gourmand', 'Leathery', 'Mineral', 'Oriental', 'Smoky', 'Animalic'],
        displayedTypes: [], // Holds currently displayed types
        showAllTypes: false, // State to manage whether all types are shown
      };
    },



  created() {
    this.displayedTypes = this.scentTypes.slice(0, 8);
  },

    methods: {
      getScentTypeColor,
      handleChipClick(type) {
        console.log(`Chip clicked: ${type}`);
        // Add additional logic here for what happens when a chip is clicked
      },
      goToPerfumesWithScentType(scentType) {
    this.$router.push({ path: '/perfumes', query: { type: scentType } });
    this.$emit('close-dialog');
    },

    shuffleTypes() {
      // Shuffle the scentTypes array and update displayedTypes with seven random types
      const shuffled = this.scentTypes
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
      this.displayedTypes = shuffled.slice(0, 8);
    }

    }

  }
</script>
  
  <style scoped>
.instructions-header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }

.dialog-container {
  background-color: white; /* White background for the dialog box */
  padding: 20px; /* Add some padding around the content */
  border-radius: 8px; /* Optional: rounded corners for the dialog box */
}

.button-container {
  display: flex;
  justify-content: center; /* This will center the button horizontally */
  margin-top: 20px; /* Optional: adds space above the button */
}

.mobile-scent-types-instr {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    margin-bottom: 15px;
    justify-content: center; /* This will center the button horizontally */
}

.v-chip:hover {
  transform: scale(1.1);
}

.v-btn {
  margin-top: 10px; /* Space between chips and button */
}

.v-chip {
  transition: all 0.42s ease-in-out;
}

.instructions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


  </style>
  